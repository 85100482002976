import React, {useState} from 'react';
import st from "./VersionMenu.module.css";
import cn from "classnames";
import RightArrow from "../../resources/RightArrow";
import LeftArrow from "../../resources/LeftArrow";
import HistoryBook from "../../resources/HistoryBook";

const VersionMenu = ({manageHistoryByMode, historyCapacity, historyIndex, updateHistory, history, projectId, isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu}) => {

    const [historyBarIsOpen, setHistoryBarIsOpen] = useState(false);
    
    const checkAndUpdateHistory = (historyIndex) => {
        if (historyIndex < history.length) {
            updateHistory(historyIndex)
        }
    }

    const switchHistoryBar = () => {
        setHistoryBarIsOpen(!historyBarIsOpen);
    }
    
    return (
        <div className={cn(st.versionMenuBlock, {[st.versionMenuBlockSwitched]: !isOpenedWorkBenchMenu})}>
            <div className={st.versionButtonsBlock}>
                <div onClick={() => manageHistoryByMode("left", "button", projectId)} className={st.leftButtonHistory}><LeftArrow/>
                </div>
                <div onClick={() => manageHistoryByMode("right", "button", projectId)} className={st.middleButtonHistory}>
                    <RightArrow/></div>
                <div onClick={switchHistoryBar} className={cn(st.middleButtonHistory, {[st.versionBarIsOpen]: historyBarIsOpen, [st.rightButtonHistory]: !historyBarIsOpen})}><HistoryBook/></div>
                <div className={cn(st.versionBarBlock, {[st.versionBarBlockIsOpen]: historyBarIsOpen})}>
                    {[...Array(historyCapacity).keys()].map((index, id) => (
                        <div key={id} className={st.versionBar}>
                            <div
                                className={cn(st.versionBarPosition, {[st.versionBarPositionCurrent]: id === historyIndex})}
                                onClick={() => checkAndUpdateHistory(id)}>
                                <div className={cn(st.versionBarPositionIndicator, {
                                    [st.versionBarPositionIndicatorGreen]: id <= historyIndex,
                                    [st.versionBarPositionIndicatorBlue]: id > historyIndex && history.length > id
                                })}></div>
                            </div>
                            <div className={st.versionBarPositionLine}></div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
};

export default VersionMenu;