const initialTablesState = {};

const tablesReducer = (state = initialTablesState, action) => {
  switch (action.type) {
      case 'SET_TABLES':
          return { ...action.payload };
      default:
          return state;
  }
}

export default tablesReducer; 