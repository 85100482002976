import React from 'react';

const HistoryBook = () => {
    let color = '#8c8c8c'
    return (
        <svg width={28} height={28} xmlns="http://www.w3.org/2000/svg" viewBox="0 68.50053405761719 472.6150207519531 335.61346435546875">
            <path
                d="M462.769 112.271h-28.124V91.108a9.846 9.846 0 0 0-5.981-9.056c-59.396-25.355-132.473-14.609-192.357 27.865C176.424 67.443 103.345 56.7 43.951 82.052a9.846 9.846 0 0 0-5.981 9.056v21.163H9.846c-5.437 0-9.846 4.408-9.846 9.846v272.151c0 5.438 4.409 9.846 9.846 9.846h452.923c5.438 0 9.846-4.409 9.846-9.846v-272.15c0-5.438-4.408-9.847-9.846-9.847zM19.692 384.422V131.964H37.97v231.295a9.847 9.847 0 0 0 13.711 9.055c46.12-19.688 101.816-14.764 151.25 12.108H19.692zm206.77-8.98c-35.936-22.9-75.911-34.79-114.498-34.79-18.629 0-36.932 2.771-54.301 8.413V122.232c0-.039.006-.076.006-.114s-.005-.076-.006-.114V97.768c52.517-19.684 116.197-8.676 168.799 29.315v248.359zm19.692-248.359c52.601-37.992 116.285-48.999 168.799-29.315v24.235c0 .038-.006.076-.006.114s.005.076.006.114v226.832c-53.348-17.329-115.515-7.576-168.799 26.378V127.083zm206.769 257.339h-183.24c49.435-26.872 105.13-31.795 151.251-12.108a9.846 9.846 0 0 0 13.711-9.055V131.964h18.278v252.458z"
                fill={color} opacity="1"/>
        </svg>


    );
};

export default HistoryBook;