import WorkBench from "../../components/WorkBench/WorkBench";
import st from "./Editor.module.css";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as Point} from "../../resources/icons/point3.svg";
import TableTopBorder from "../../components/TableParts/TableTopBorder";
import TableField from "../../components/TableParts/TableField";
import TableBottomBorder from "../../components/TableParts/TableBottomBorder";
import TableHeader from "../../components/TableParts/TableHeader";
import TableTopBorderSelect from "../../components/TableParts/TableTopBorderSelect";
import TableBorderSelect from "../../components/TableParts/TableBorderSelect";
import TableBottomBorderSelect from "../../components/TableParts/TableBottomBorderSelect";
import { v4 as uuidv4 } from 'uuid';
import TableBorderSelectWithPoint from "../../components/TableParts/TableBorderSelectWithPoint";
import DevelopmentConsole from "../../components/DevelopmentConsole/DevelopmentConsole";
import RelationTypeButton from "../../resources/svgButtons/RelationTypeButton";
import {FieldTypes} from "../../service/enum/FielsTypes";
import {RelationTypes} from "../../service/enum/RelationTypes";
import {SaveMode} from "../../service/enum/SaveMode";
import {createNotification, NotificationStatus} from "../../service/Notification";
import {Arrow, CreationArrow} from "./editorComponents/Arrow";
import {Positions} from "../../service/enum/Positions";
import {RelationError} from "../../service/enum/RelationError";
import VersionMenu from "../../components/VersionMenu/VersionMenu";
import {useDispatch, useSelector} from "react-redux";
import {EDITOR_LOGS_ENABLED, MAX_AMOUNT_OF_TABLES, MAX_HISTORY, TABLE_WIDTH} from "../../service/consts";
import {updateRelationsRedux, updateTablesRedux} from "../../redux/actions/appActions";
import {useHistory} from "../../service/History";
import {getRandomColor} from "../../service/utils/utils";
import cn from "classnames";
import {Constraints} from "../../service/enum/Constraints";

export const Editor = () => {

    const dispatch = useDispatch();
    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        // После первого рендеринга установите isInitialRender в false
        // setIsInitialRender(false);
    }, []);
    const step = 10;
    const [isInitialUpdate, setIsInitialUpdate] = useState(true)
    const [openedTableIds, setOpenedTableIds] = useState(new Set());
    
    let {history, setHistory, historyIndex, manageHistoryBeforeSave, manageHistoryByMode, updateHistory} = useHistory();

    const [tables, setTables] = useState({})

    const tablesRedux = useSelector((state) => state.tablesRedux);
    useEffect(() => {
        log("[TABLES_REDUX] Обновление tables из redux")
        if (JSON.stringify(tables) !== JSON.stringify(tablesRedux)) {
            log("Обновление из вне")
            setTables({...tablesRedux})
        } else {
            log("Данные идентичны")
        }
    }, [tablesRedux]);
    
    useEffect(() => {
        log("[TABLES] Обновление tablesRedux в redux")
        if (JSON.stringify(tables) !== JSON.stringify(tablesRedux)) {
            log("Обновление redux")
            dispatch(updateTablesRedux(tables))
        } else {
            log("Данные идентичны")
        }
    }, [tables]);
    
    const log = (message) => {
        if (EDITOR_LOGS_ENABLED) {
            console.log("[EDITOR] " + message)
        }
    }

    const [relations, setRelations] = useState({})

    const relationsRedux = useSelector((state) => state.relationsRedux);
    useEffect(() => {
        log("[RELATIONS_REDUX] Обновление relations из redux")
        if (JSON.stringify(relations) !== JSON.stringify(relationsRedux)) {
            log("Обновление из вне")
            setRelations({...relationsRedux})
        } else {
            log("Данные идентичны")
        }
    }, [relationsRedux]);

    useEffect(() => {
        log("[RELATIONS] Обновление relationsRedux в redux")
        if (JSON.stringify(relations) !== JSON.stringify(relationsRedux)) {
            log("Обновление redux")
            dispatch(updateRelationsRedux(relations))
        } else {
            log("Данные идентичны")
        }
    }, [relations]);
    
    const [scale, setScale] = useState(1);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 }); // позиция курсора относительно масштаба (чем больше масштаб по модулю, там больше позиция курсора (по сути, смещение по масштабу))
    const [isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu] = useState(true);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [selectedTablesId, setSelectedTablesId] = useState([]);
    const [selectedTableDelta, setSelectedTableDelta] = useState({});
    const [selectedField, setSelectedField] = useState(null);
    const [selectedRelationId, setSelectedRelationId] = useState(null);
    const [isDraggingTable, setIsDraggingTable] = useState(false);
    const [tableMoved, setTableMoved] = useState(false);
    const [canvas, setCanvas] = useState({
        isDragging: false,
        initialMouseCoords: { x: 0, y: 0 },
        movingMouseCoords: { x: 0, y: 0 },
        deltaX: 0,
        deltaY: 0
    });
    
    const [selectingArea, setSelectingArea] = useState({
        isSelecting: false,
        startMouseCoords: { x: 0, y: 0 },
        endMouseCoords: { x: 0, y: 0 }
    })
    

    // координаты мыши для вычисления позиции кончика стрелки при создании
    const [mouseCoords, setMouseCoords] = useState();
    const [projectId, setProjectId] = useState(1)
    
    useEffect(() => {
        let project = localStorage.getItem('project_' + projectId);
        let tables = {};
        let relations = {};
        
        if (project) {
            project = JSON.parse(project);
            if (project.tables) {
                tables = project.tables
                if (project.relations) {
                    relations = project.relations
                    setRelations(relations)
                }
                setTables(tables)
            }
            history.push(JSON.stringify({tables: tables, relations: relations}))
            setHistory([...history])

            // Код сбрасывает связи
        //     Object.entries(tables).forEach(([key, value]) => {
        //         Object.entries(value.fields).forEach(([key2, value2]) => {
        //             tables[key].fields[key2] = {...value2, relations: []]}
        //         });
        //     });
        //    
        }
        setTimeout(() => {
            setIsInitialUpdate(false)
        }, 1000)
        // setRelations({})
        document.body.style.zoom = "100%";
    }, [])

    const createTable = (workBenchX, workBenchY, data = null) => {
        if (Object.keys(tables).length === MAX_AMOUNT_OF_TABLES) {
            createNotification(NotificationStatus.WARNING, "Максимально разрешенное кол-во таблиц уже создано!", "")
            return
        }
        if (data) {
            createTableOnSample(workBenchX, workBenchY, data);
        } else {
            createNewTable(workBenchX, workBenchY);
        }
    }
    
    const createNewTable = (workBenchX, workBenchY) => {
        const tableId = uuidv4();
        tables[`${tableId}`] = {
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            name: "table",
            color: getRandomColor(Object.keys(tables).length),
        }
        let fields = {}
        fields[uuidv4()] = {
            name: "id",
            type: FieldTypes.BIGINT,
            relations: [],
            constraints: [Constraints.PRIMARY_KEY]
        }
        tables[`${tableId}`].fields = fields
        updateTables(tables, "createNewTable");
        setSelectedTableId(tableId);
    }

    const createTableOnSample = (workBenchX, workBenchY, data) => {
        const copyTableId = uuidv4();
        let newTableData = JSON.parse(JSON.stringify(data));
        Object.entries(newTableData.fields).forEach(([key, value]) => {
            if (newTableData.fields[key].constraints.includes(Constraints.PRIMARY_KEY)) {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: [Constraints.PRIMARY_KEY]}
            } else {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: []}
            }
            delete newTableData.fields[key];
        });
        tables[`${copyTableId}`] = {
            ...newTableData,
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            color: getRandomColor(Object.keys(tables).length),
        }
        
        
        updateTables(tables, "createTableOnSample");
        setSelectedTableId(copyTableId);
    }
    
    // selectedNewTableId - id новой таблицы для выделения сразу после создания
    const updateTables = (tables, methodName, needSaveToLocalStorage = true) => {
        setTables({...tables})
        if (needSaveToLocalStorage) {
            saveToLocalStorage(SaveMode.TABLES, methodName, tables)
        }
    }

    const updateRelations = (relations, methodName) => {
        setRelations({...relations})
        saveToLocalStorage(SaveMode.RELATIONS, methodName, null, relations)
    }

    const updateTablesAndRelations = (tables, relations, methodName) => {
        setTables({...tables})
        setRelations({...relations})
        saveToLocalStorage(SaveMode.ALL, methodName, tables, relations)
    }

    // добавлена передача tables и relations для получения актуальных данных (redux может не успеть подгрузить актуальные данные)
    const saveToLocalStorage = (mode, methodName, newTables = null, newRelations = null) => {
        const tablesToSave = newTables === null ? tables : newTables;
        const relationsToSave = newRelations === null ? relations : newRelations;
        
        let dataToSave;
        manageHistoryBeforeSave(tablesToSave, relationsToSave);
        dataToSave = {tables: tablesToSave, relations: relationsToSave}
        localStorage.setItem("project_" + projectId, JSON.stringify(dataToSave));
        console.log(`Данные ${mode} сохранены в localStorage из ${methodName}`);
    };

    const updateFieldType = (tableId, fieldId, newType) => {
        tables[tableId].fields[fieldId].type = newType;
        // проверка, что типы данных у связанных полей такие же
        const fieldRelations = tables[tableId].fields[fieldId].relations;
        for (let i = 0; i < fieldRelations.length; i++) {
            const relation = relations[fieldRelations[i]]
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            const hasRelationTypeError = newType !== tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type
            manageRelationError(hasRelationTypeError, RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS, fieldRelations[i])
            if (hasRelationTypeError) {
                createNotification(NotificationStatus.WARNING, `Связываемые поля имеют несовместимые типы данных (${newType}, ${tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type})`, "Ошибка типа данных")
            }
        }
        
        updateTablesAndRelations(tables, relations, "changeFieldType")
    }

    // метод обрабатывает выбор/выделение/доп. выделение через ctrl и рассчитывает отклонение таблицы от курсора в случае перемещения
    const selectTable = (e, newSelectedTableId) => {
        unSelectLastSelectedTable();
        if (e) { // было нажатие по таблице на канвасе
            e.stopPropagation();
            let selectedTables = selectedTablesId;
            if (e.ctrlKey) {
                if (selectedTables.length === 0 && selectedTableId) { // если нет выделенных таблиц, то выбранная таблица добавляется в выделенные
                    selectedTables.push(selectedTableId)
                }
                if (selectedTableId !== newSelectedTableId) { // если выбранная таблица не среди выделенных
                    if (selectedTables.indexOf(newSelectedTableId) === -1) { // если таблица не среди выделенных
                        selectedTables.push(newSelectedTableId)
                    } else {
                        selectedTables = selectedTables.filter(t => t !== newSelectedTableId);
                    }
                }
                setSelectedTablesId([...selectedTables])
            } else {
                if (selectedTablesId.indexOf(newSelectedTableId) === -1) { // если таблица не была выделена, то обнуляем выделение и выделяем новую
                    selectedTables = []
                    setSelectedTablesId(selectedTables)
                    setSelectedTableId(newSelectedTableId);
                }
            }
            setIsDraggingTable(true);
            if (selectedTables.length > 0) { // если выделено несколько
                for (let selectedTable of selectedTables) {
                    let deltaX = (e.clientX - getCurrentRefOffset(0)) / scale - tables[selectedTable].x;
                    let deltaY = (e.clientY - getCurrentRefOffset(1)) / scale - tables[selectedTable].y;
                    selectedTableDelta[selectedTable] = {x: deltaX, y: deltaY}
                }
                setSelectedTableDelta({...selectedTableDelta})
            } else { // если выделена одна
                let deltaX = (e.clientX - getCurrentRefOffset(0)) / scale - tables[newSelectedTableId].x;
                let deltaY = (e.clientY - getCurrentRefOffset(1)) / scale - tables[newSelectedTableId].y;
                selectedTableDelta[newSelectedTableId] = {x: deltaX, y: deltaY}
                setSelectedTableDelta({...selectedTableDelta})
            }
        } else {
            setSelectedTableId(newSelectedTableId);
        }
    }

    const unSelectLastSelectedTable = () => {
        setSelectedTableId(null);
    }

    const selectCanvas = (e) => {
        e.stopPropagation();
        if (e.button === 0) { // ЛКМ
            setSelectedField(null)
            unSelectLastSelectedTable();
            // Начальная и смещенная позиции находятся в одной точке в начале смещения канваса
            setCanvas({...canvas, isDragging: true, initialMouseCoords: {x: e.clientX, y: e.clientY}, movingMouseCoords: {x: e.clientX, y: e.clientY}})
        } else if (e.button === 2) { // ПКМ
            setSelectedTablesId([])
            let x = (e.clientX - getCurrentRefOffset(0)) / scale;
            let y = (e.clientY - getCurrentRefOffset(1)) / scale;
            setSelectingArea({ ...selectingArea, isSelecting: true, startMouseCoords: {x: x, y: y}, endMouseCoords: {x: x, y: y}})
        }
    }

    const [lastMouseMoveTime, setLastMouseMoveTime] = useState(0);
    
    const moveTable = (e) => {
        if (selectedTablesId.length > 0 && isDraggingTable === true) {
            for (let selectedTable of selectedTablesId) {
                tables[selectedTable] = { ...tables[selectedTable], x: (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedTable].x, y: (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedTable].y } // прибавляем смещение относительно scale
            }
        } else {
            if (selectedTableId === null || isDraggingTable === false) return;
            // изменяем координаты смещенной таблицы
            tables[selectedTableId] = { ...tables[selectedTableId], x: (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedTableId].x, y: (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedTableId].y } // прибавляем смещение относительно scale
        }
        setTables({ ...tables})
    }

    const moveCanvas = (e) => {
        if (canvas.isDragging) {
            // изменяем координаты смещенной позиции канваса
            setCanvas({
                ...canvas,
                movingMouseCoords: { x: e.clientX, y: e.clientY },
            });
        }
    };
    
    const getTableHeight = (tableId) => {
        return Object.keys(tables[tableId].fields).length * 20 + 56;
    }

    const checkSelectedTables = () => {
        let startCoordsX = selectingArea.startMouseCoords.x < selectingArea.endMouseCoords.x ? selectingArea.startMouseCoords.x : selectingArea.endMouseCoords.x;
        let startCoordsY = selectingArea.startMouseCoords.y < selectingArea.endMouseCoords.y ? selectingArea.startMouseCoords.y : selectingArea.endMouseCoords.y;
        let endCoordsX = selectingArea.startMouseCoords.x < selectingArea.endMouseCoords.x ? selectingArea.endMouseCoords.x : selectingArea.startMouseCoords.x;
        let endCoordsY = selectingArea.startMouseCoords.y < selectingArea.endMouseCoords.y ? selectingArea.endMouseCoords.y : selectingArea.startMouseCoords.y;

        let selectedTables = []
        
        for (let tableId in tables) {
            if (tables[tableId].x >= startCoordsX && tables[tableId].y >= startCoordsY - 8 && 
                tables[tableId].x + TABLE_WIDTH <= endCoordsX && tables[tableId].y + getTableHeight(tableId) <= endCoordsY) {
                selectedTables.push(tableId)
            }
        }
        if (selectedTables.length > 0 && selectedTableId) { // может случиться дублирование, поэтому позже используем set
            selectedTables.push(selectedTableId)
        }
        
        setSelectedTablesId([...new Set(selectedTables)])
    };
    
    const removeCTLWheel = (e) => {
        if (e.ctrlKey) {
            // Предотвращаем стандартное действие (зум)
            e.preventDefault();
        }
    }
    
    const clickFieldRelationPoint = (e, fieldId, position) => {
        e.stopPropagation()
        setSelectedField({
            fieldId: fieldId,
            position: position
        })

        const startDeltaX = position === Positions.RIGHT ? TABLE_WIDTH : 0;

        const fieldStep = 20;
        let fieldIndex = Object.keys(tables[selectedTableId].fields).findIndex((findFieldId) => findFieldId === fieldId);
        const startDeltaY = 54 + fieldStep * fieldIndex;
        
        setMouseCoords({ x: tables[selectedTableId].x + startDeltaX, y: tables[selectedTableId].y + startDeltaY})
    }
    
    const createRelation = (e, targetTableId, targetFieldId) => {
        if (targetTableId && selectedField && selectedField.fieldId !== targetFieldId) { // если поле не то же самое
            const relationId = uuidv4()
            let relationErrors = []
            if (!(tables[selectedTableId].fields[selectedField.fieldId].type === tables[targetTableId].fields[targetFieldId].type)) { // если типы полей не совпадают
                relationErrors.push(RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS)
                createNotification(NotificationStatus.WARNING, `У связываемых полей несовместимые типы данных [${tables[selectedTableId].fields[selectedField.fieldId].type}, ${tables[targetTableId].fields[targetFieldId].type}]`, "Невозможная связь")
            }
            relations[relationId] = {
                table1: selectedTableId,
                table1Field: selectedField.fieldId,
                table2: targetTableId,
                table2Field: targetFieldId,
                relationType: RelationTypes.ONE_TO_ONE,
                relationErrors: relationErrors,
            }
            
            tables[selectedTableId].fields[selectedField.fieldId].relations.push(relationId)
            tables[targetTableId].fields[targetFieldId].relations.push(relationId)
            
            // добавляем constraint ForeignKey полю, с которым связали PK
            checkFKConstraints(selectedTableId, selectedField.fieldId)
            updateTablesAndRelations(tables, relations, "createRelation")
        }
    }
    
    // проверяет связи поля с другими полями и расставляет FK, если надо
    const checkFKConstraints = (selectedTableId, selectedFieldId) => {
        for (const relationId of tables[selectedTableId].fields[selectedFieldId].relations) {
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relations[relationId], selectedTableId, selectedFieldId);
            const targetTableId = otherTableIdAndFieldId.tableId;
            const targetFieldId = otherTableIdAndFieldId.fieldId;
            // Если оба поля не имеют вместе PrimaryKey
            if (!(hasConstraint(selectedTableId, selectedFieldId, Constraints.PRIMARY_KEY) && hasConstraint(targetTableId, targetFieldId, Constraints.PRIMARY_KEY))) {
                if (hasConstraint(selectedTableId, selectedFieldId, Constraints.PRIMARY_KEY) && !hasConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY)) { // если у первого полю есть PK, а у второго нет FK
                    setConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY)
                } else if (hasConstraint(targetTableId, targetFieldId, Constraints.PRIMARY_KEY) && !hasConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)) { // наоборот
                    setConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)
                } else { // ни у кого нет PK
                    // проверяем, что у полей нет FK ограничений от других связей
                    // если нет, то ограничения удаляются
                    if (!hasOtherFKRelations(selectedTableId, selectedFieldId)) {
                        deleteConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY);
                    }
                    if (!hasOtherFKRelations(targetTableId, targetFieldId)) {
                        deleteConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY);
                    }
                }
            } else { // если оба поля имеют PK
                if (checkHasCompositeConstraint(targetTableId, Constraints.PRIMARY_KEY)) { // если у целевой таблицы составной PK, то ей ставим FK
                    setConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY)
                    if (!hasOtherFKRelations(selectedTableId, selectedFieldId)) {
                        deleteConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY);
                    }
                } else if (checkHasCompositeConstraint(selectedTableId, Constraints.PRIMARY_KEY)) { // если у основной таблицы составной PK, то ей ставим FK
                    setConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)
                    if (!hasOtherFKRelations(targetTableId, targetFieldId)) {
                        deleteConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY);
                    }
                }  else { // Иначе ставим FK у выбранной (откуда вели стрелку) (не проверяем у выбранной таблицы составной PK, тк в любом случае ставим FK)
                    setConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)
                }
            }
        }
    }
    
    const checkHasCompositeConstraint = (tableId, constraint) => {
        let count = 0;
        for (const fieldId in tables[tableId].fields) {
            if (hasConstraint(tableId, fieldId, constraint)) {
                count++;
                if (count > 1) {
                    return true;
                }
            }
        }
        return false;
    }
    
    const setConstraint = (tableId, fieldId, constraint) => {
        if (!hasConstraint(tableId, fieldId, constraint)) {
            tables[tableId].fields[fieldId]?.constraints?.push(constraint);
        }
    }

    const deleteConstraint = (tableId, fieldId, constraint) => {
        tables[tableId].fields[fieldId].constraints = tables[tableId].fields[fieldId]?.constraints?.filter(c => c !== constraint);
    }
    
    const switchConstraint = (tableId, fieldId, constraint) => {
        if (hasConstraint(tableId, fieldId, constraint)) {
            deleteConstraint(tableId, fieldId, constraint)
        } else {
            setConstraint(tableId, fieldId, constraint)
        }
        updateTables(tables, "switchConstraint")
    }
    
    
    const hasConstraint = (tableId, fieldId, constraint) => {
        return tables[tableId].fields[fieldId]?.constraints?.includes(constraint);
    }

    // Обработка отжатия ЛКМ
    useEffect(() => {
        const stopDragging = () => {
            if (selectingArea.isSelecting) {
                setSelectingArea({ ...selectingArea, isSelecting: false})
            }
            if (isDraggingTable) {
                setIsDraggingTable(false)
                if (tableMoved) {
                    saveToLocalStorage(SaveMode.TABLES, "stopDragging", tables)
                }
                setTableMoved(false)
                setSelectedRelationId(null)
            }
            if (canvas.isDragging) {
                setCanvas({...canvas, isDragging: false,
                    deltaX: canvas.deltaX + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x, deltaY: canvas.deltaY + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y, // прибавляем к делтам смещение
                    initialMouseCoords: {x: 0, y: 0}, movingMouseCoords: {x: 0, y: 0} // обнуляем начальную и смещенную позиции
                })
                setSelectedRelationId(null)
            }
            if (selectedField) {
                setSelectedField(null)
                setSelectedRelationId(null)
            }
        }

        window.addEventListener('mouseup', stopDragging);
        return () => {
            window.removeEventListener(
                'mouseup',
                stopDragging,
            );
        };
    }, [tables, relations, history, historyIndex, selectedField, selectingArea, isDraggingTable, tableMoved, canvas]);

    useEffect(() => {
        const handleWindowMouseMove = (e) => {
            const currentTime = Date.now();
            const isAvailableByTime = currentTime - lastMouseMoveTime > 8; // небольшое ограничение обновления кадров, чтобы не перегружать процессор (16 - примерно 60fps, 8 - 120fps)
            if (!isAvailableByTime) return;
            if (selectingArea.isSelecting) {
                setSelectingArea({...selectingArea, endMouseCoords: {x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale}})
                checkSelectedTables();
                setLastMouseMoveTime(currentTime);
            }
            else if (selectedField) {
                setMouseCoords(    {x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale})
                setLastMouseMoveTime(currentTime);
            } else if (isDraggingTable){
                if (!tableMoved)
                    setTableMoved(true)
                moveTable(e);
                setLastMouseMoveTime(currentTime);
            }
            else if (canvas.isDragging) {
                moveCanvas(e);
                setLastMouseMoveTime(currentTime);
            }
        }
        
        window.addEventListener('mousemove', handleWindowMouseMove);
        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
        };
    }, [tables, selectingArea, selectedField, scale, isDraggingTable, tableMoved, canvas, cursorPosition, selectedTableId, selectedTablesId, selectedTableDelta, lastMouseMoveTime]);
    
    // Отключение колесика + ctrl
    useEffect(() => {
        window.addEventListener('wheel', removeCTLWheel, { passive: false });
        return () => {
            window.removeEventListener(
                'wheel',
                removeCTLWheel,
            );
        };
    }, []);

    const handleWheel = (e) => {
        if (scale <= 0.3 && e.deltaY > 0) return // e.deltaY > 0 значит колесико вниз
        else if (scale >= 10 && e.deltaY < 0) return;
        const scaleAdjust = e.deltaY > 0 ? 0.9 : 1.1; // Значение изменения масштаба
        const newScale = scale * scaleAdjust;

        // Позиция канваса должна корректироваться, чтобы курсор оставался над той же точкой контента
        const newPos = {
            x: cursorPosition.x - (e.clientX - cursorPosition.x - canvas.deltaX) * (scaleAdjust - 1),
            y: cursorPosition.y - (e.clientY - cursorPosition.y - canvas.deltaY) * (scaleAdjust - 1)
        };

        // Обновление состояния
        setScale(newScale);
        setCursorPosition(newPos);
    };

    // отключение контекстного меню
    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    // текущее смещение относительно курсора (используется, при изменении масштаба + смещение канваса при перемещении - изначальные координаты канваса + имеющееся смещение канваса
    const getCurrentOffset = (coordinate) => {
        if (coordinate === 0)
            return cursorPosition.x + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y + canvas.deltaY;
    }

    // вычисление смещения координат под мышкой относительно смещения канваса и смещения содержимого после масштабирования
    const getCurrentRefOffset = (coordinate) => {
        if (coordinate === 0)
            return cursorPosition.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.deltaY;
    }

    const deleteTable = (e, tableId, disableUpdateLocalStorage = false) => {
        if (selectedTableId === tableId) {
            setSelectedTableId(null)
        }
        e.stopPropagation()
        deleteRelations(tableId)
        delete tables[tableId];
        if (!disableUpdateLocalStorage) {
            updateTablesAndRelations(tables, relations, "deleteTable")
        }
    }
    
    const deleteField = (e, tableId, fieldId) => {
        tables[tableId].fields[fieldId].relations.map(relationId => {
            deleteRelation(relationId)
        })
        delete tables[tableId].fields[fieldId]
        updateTablesAndRelations(tables, relations, "deleteField")
    }

    const deleteRelations = (tableId) => {
        // получение всех связей таблички 
        const relationsToRemoveIds = [];
        for (let i = 0; i < Object.keys(tables[tableId].fields).length; i++) {
            const fieldId = Object.keys(tables[tableId].fields)[i];
            if (tables[tableId].fields[fieldId].relations.length) {
                relationsToRemoveIds.push(...tables[tableId].fields[fieldId].relations)
            }
        }

        // удаление всех связей
        for (let i = 0; i < relationsToRemoveIds.length; i++) {
            deleteRelation(relationsToRemoveIds[i])
        }
    }
    
    const selectRelationType = (relationType) => {
        relations[selectedRelationId].relationType = relationType;
        updateRelations(relations, "selectRelationType");
        setSelectedRelationId(null)
    }
    
    const deleteRelation = (relationId, needRelationUpdate = false, needTableUpdate = false) => {
        if (!relations[relationId]) return;
        const relationTableId1 = relations[relationId].table1;
        const relationTableFieldId1 = relations[relationId].table1Field;
        const relationTableId2 = relations[relationId].table2;
        const relationTableFieldId2 = relations[relationId].table2Field;
        // чистим связь в связанных таблицах
        tables[relationTableId1].fields[relationTableFieldId1].relations = tables[relationTableId1].fields[relationTableFieldId1].relations.filter(( relation ) => relation !== relationId);
        tables[relationTableId2].fields[relationTableFieldId2].relations = tables[relationTableId2].fields[relationTableFieldId2].relations.filter(( relation ) => relation !== relationId);

        // чистим FK
        if (hasConstraint(relationTableId1, relationTableFieldId1, Constraints.FOREIGN_KEY)) {
            if (!hasOtherFKRelations(relationTableId1, relationTableFieldId1)) {
                deleteConstraint(relationTableId1, relationTableFieldId1, Constraints.FOREIGN_KEY);
            }
        }
        if (hasConstraint(relationTableId2, relationTableFieldId2, Constraints.FOREIGN_KEY)) {
            if (!hasOtherFKRelations(relationTableId2, relationTableFieldId2)) {
                deleteConstraint(relationTableId2, relationTableFieldId2, Constraints.FOREIGN_KEY);
            }
        }
        
        delete relations[relationId];
        
        if (needRelationUpdate && needTableUpdate) {
            updateTablesAndRelations(tables, relations, "deleteRelation")
        }
        else if (needRelationUpdate) {
            updateRelations(relations, "deleteRelation")
        } 
        else if (needTableUpdate) {
            updateTables(tables, "deleteRelation")
        }
        
        if (selectedRelationId === relationId) {
            setSelectedRelationId(null)
        }
    }
    
    const hasOtherFKRelations = (tableId, fieldId) => {
        const relationsIds = tables[tableId].fields[fieldId].relations
        if (!relationsIds.length) {
            return false;
        }
        for (let i = 0; i < relationsIds.length; i++) {
            const relation = relations[relationsIds[i]];
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            if (tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId]?.constraints?.includes(Constraints.PRIMARY_KEY) &&
                !checkHasCompositeConstraint(otherTableIdAndFieldId.tableId, Constraints.PRIMARY_KEY)) { // проверяем, что связь не с таблицей, у которой есть композитный первичный ключ 
                return true;
            }
        }
        return false;
    }
    
    // Возвращает из связей другую таблицу и поле
    const getOtherTableAndFieldFromRelation = (relation, tableId, fieldId) => {
        if (relation.table1 === tableId && relation.table1Field === fieldId) {
            return {tableId: relation.table2, fieldId: relation.table2Field}
        } else if (relation.table2 === tableId && relation.table2Field === fieldId) {
            return {tableId: relation.table1, fieldId: relation.table1Field}
        }
    }

    const handleKeyDown = (event) => {
        if (document.activeElement.tagName.toLowerCase() === 'input') {
            return;
        }
        if (event.keyCode === 46) { // проверяем код клавиши delete
            if (selectedTablesId.length > 0) {
                for (let tableId of selectedTablesId) {
                    deleteTable(event, tableId, true);
                }
                updateTablesAndRelations(tables, relations, "handleKeyDown deleteTable")
                setSelectedTablesId([])
            }
            else if (selectedTableId && !selectedRelationId) {
                deleteTable(event, selectedTableId);
            } else if (selectedRelationId) {
                deleteRelation(selectedRelationId, true, true)
                setSelectedRelationId(null);
            }
        }
        if (event.ctrlKey && (event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'я')) {
            manageHistoryByMode(event, "keydown", projectId);
        }
    }
    
    const selectRelation = (e, relationId) => {
        setMouseCoords({x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale})
        setSelectedRelationId(relationId)
    }
    
    // метод для управления ошибками связей    
    const manageRelationError = (hasRelationError, relationError, relationId) => {
        if (relations[relationId]?.relationErrors === null) { // если связь не имеет ошибок (сделано для обратной совместимости, ранее не было данного поля)
            relations[relationId].relationErrors = [];
        } 
        if (hasRelationError && !relations[relationId]?.relationErrors?.includes(relationError)) {
            relations[relationId]?.relationErrors?.push(relationError)
        } else if (!hasRelationError && relations[relationId]?.relationErrors?.includes(relationError)) {
            relations[relationId].relationErrors = relations[relationId]?.relationErrors?.filter(relationErr => relationErr !== relationError)
        }
    }
    
    // метод сортировки словаря связей относительно кол-ва ошибок (нужен для отобажения ошибочных связей выше остальных)
    const getSortedRelationsByErrors = (relations) => {
        let array = Object.keys(relations).map(key => ({
            id: key,
            ...relations[key]
        }));

        array.sort((a, b) => {
            let lenErrA = a?.relationErrors?.length;
            let lenErrB = b?.relationErrors?.length;
            if (lenErrA === null || lenErrA === undefined) { // нужно для обратной совместимости
                lenErrA = 0;
            }
            if (lenErrB === null || lenErrB === undefined) {
                lenErrB = 0;
            }
            return (lenErrB === 0 ? -1 : lenErrB) - (lenErrA === 0 ? -1 : lenErrA);
        }).reverse();
        
        let sortedObj = {};
        array.forEach(item => {
            sortedObj[item.id] = item;
            delete sortedObj[item.id].id;
        });
        return sortedObj
    }
    
    const changeRelationType = (relation, relationType) => {
        relation.relationType = relationType
        manageRelationError(isRelationImpossible(relation), RelationError.IMPOSSIBLE_RELATION, selectedRelationId)
        selectRelationType(relationType)
    }

    // метод проверки связи между двумя таблицами на основе первичных ключей
    const isRelationImpossible = (relation) => {
        const relationType = relation.relationType;
        if (relationType === RelationTypes.MANY_TO_ONE &&
            tables[relation.table1].fields[relation.table1Field]?.constraints?.includes(Constraints.PRIMARY_KEY)) {
            if (checkHasCompositeConstraint(relation.table1, Constraints.PRIMARY_KEY) && hasConstraint(relation.table2, relation.table2Field, Constraints.PRIMARY_KEY)) { // Если у таблицы 1 сост. первичный ключ и у второй поле явл. перв. ключом
                return false; 
            }
            createNotification(NotificationStatus.WARNING, `Отношение ${relationType} между ${tables[relation.table1].name} и ${tables[relation.table2].name} невозможно`, 'Ошибка типа связи')
            return true;
        } else if (relationType === RelationTypes.ONE_TO_MANY && tables[relation.table2].fields[relation.table2Field]?.constraints?.includes(Constraints.PRIMARY_KEY)) {
            if (checkHasCompositeConstraint(relation.table2, Constraints.PRIMARY_KEY) && !hasConstraint(relation.table1, relation.table1Field, Constraints.FOREIGN_KEY)) { // Если у таблицы 2 сост. первичный ключ
                return false;
            }
            createNotification(NotificationStatus.WARNING, `Отношение ${relationType} между ${tables[relation.table1].name} и ${tables[relation.table2].name} невозможно`, 'Ошибка типа связи')
            return true;
        }
        return false;
    }

    const switchPrimaryKey = (tableId, fieldId) => {
        if (tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)) {
            tables[tableId].fields[fieldId].constraints = tables[tableId].fields[fieldId].constraints.filter(constraint => constraint !== Constraints.PRIMARY_KEY);
        } else {
            tables[tableId].fields[fieldId].constraints.push(Constraints.PRIMARY_KEY);
        }
        checkFKConstraints(tableId, fieldId);
        checkRelationsPossibilityForTable(tableId);
        updateTablesAndRelations(tables, relations, "switchPrimaryKey")
    }
    
    // метод проверки корректности всех связей у таблицы
    const checkRelationsPossibilityForTable = (tableId) => {
        // проходимся по всем полям таблицы и по их связям
        for (const fieldId in tables[tableId].fields) {
            for (const relationId of tables[tableId].fields[fieldId].relations) {
                manageRelationError(isRelationImpossible(relations[relationId]), RelationError.IMPOSSIBLE_RELATION, relationId)
            }
        }
    }

    return (
        <div style={{width: "100%", height: "100vh", overflow: "hidden"}} tabIndex="0" onKeyDown={handleKeyDown} >

            <WorkBench createTable={createTable} selectTable={selectTable}
                       selectedTableId={selectedTableId} selectedTablesId={selectedTablesId} updateTables={updateTables}
                       deleteTable={deleteTable} deleteField={deleteField} updateFieldType={updateFieldType}
                       openedTableIds={openedTableIds} setOpenedTableIds={setOpenedTableIds} switchPrimaryKey={switchPrimaryKey} switchConstraint={switchConstraint} isOpenedWorkBenchMenu={isOpenedWorkBenchMenu} setIsOpenedWorkBenchMenu={setIsOpenedWorkBenchMenu}/>
            <VersionMenu manageHistoryByMode={manageHistoryByMode} historyCapacity={MAX_HISTORY} projectId={projectId}
                         historyIndex={historyIndex} updateHistory={updateHistory} history={history}
                         isOpenedWorkBenchMenu={isOpenedWorkBenchMenu} setIsOpenedWorkBenchMenu={setIsOpenedWorkBenchMenu}/>
            <DevelopmentConsole relations={relations} setRelations={setRelations}
                                saveToLocalStorage={saveToLocalStorage} projectId={projectId}/>
            <div className={st.canvasBlock} onContextMenu={handleContextMenu}>
                <svg
                    width={window.innerWidth}
                    height={window.innerHeight}
                    onWheel={handleWheel}
                >
                    <defs>
                        <pattern id="backgroundPattern" patternUnits="userSpaceOnUse" width={step * scale} height={step * scale} x={getCurrentOffset(0)} y={getCurrentOffset(1)}>
                            <Point width={step * scale} height={step * scale} />
                        </pattern>
                    </defs>
                    <rect onMouseDown={selectCanvas} x={0} y={0} width={window.innerWidth} height={window.innerHeight} fill="url(#backgroundPattern)"/>
                    <g
                        transform={`translate(${getCurrentOffset(0)}, ${getCurrentOffset(1)}) scale(${scale})`}>
                        {Object.keys(getSortedRelationsByErrors(relations)).map((relationId) => (
                            <Arrow key={relationId} relationId={relationId} relation={relations[relationId]}
                                   tables={tables} selectedRelationId={selectedRelationId}
                                   setSelectedRelationId={setSelectedRelationId} selectRelation={selectRelation}
                                   isInitialUpdate={isInitialUpdate}/>
                        ))}
                        {selectedField &&
                            <CreationArrow selectedTableId={selectedTableId} tables={tables} mouseCoords={mouseCoords}
                                           selectedField={selectedField}/>
                        }
                        {Object.keys(tables).map((tableId) => (
                            <svg onAnimationEnd={() => setIsInitialRender(false)} className={cn({[st.table]: isInitialRender})} key={tableId} x={step * Math.round(tables[tableId].x / step)}
                                 y={step * Math.round(tables[tableId].y / step) + step / 2} fill="none"
                                 xmlns="http://www.w3.org/2000/svg" onMouseDown={(e) => selectTable(e, tableId)}>
                                {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                    <>
                                        <TableTopBorderSelect isGroupSelection={selectedTablesId.includes(tableId)}/>
                                        <TableBorderSelect x={4} y={6} height={38} isGroupSelection={selectedTablesId.includes(tableId)}/>
                                        <TableBorderSelect x={TABLE_WIDTH + 5} y={6} height={38} isGroupSelection={selectedTablesId.includes(tableId)}/>
                                    </>
                                )}
                                <TableTopBorder color={tables[tableId].color}/>
                                <TableHeader text={tables[tableId].name} tableId={tableId}/>
                                <g>
                                    {Object.keys(tables[tableId]?.fields).map((fieldId, fieldIndex) => (
                                        <svg key={fieldId}>
                                            <TableField deltaY={fieldIndex} key={fieldId}
                                                        field={tables[tableId].fields[fieldId]} tableId={tableId}
                                                        fieldId={fieldId}
                                                        selectRelationField={createRelation}
                                                        isPrimaryKey={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.PRIMARY_KEY)}
                                                        isForeignKey={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.FOREIGN_KEY)}
                                                        setOpenedTableIds={setOpenedTableIds}
                                            />
                                            {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                                <>
                                                    <TableBorderSelectWithPoint x={4} y={44 + 20 * fieldIndex}
                                                                                height={fieldIndex === Object.keys(tables[tableId].fields).length - 1 ? 19 : 20}
                                                                                selectFieldPoint={clickFieldRelationPoint}
                                                                                fieldId={fieldId}
                                                                                position={Positions.LEFT}
                                                                                isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                    <TableBorderSelectWithPoint x={TABLE_WIDTH + 5} y={44 + 20 * fieldIndex}
                                                                                height={20}
                                                                                selectFieldPoint={clickFieldRelationPoint}
                                                                                fieldId={fieldId}
                                                                                position={Positions.RIGHT}
                                                                                isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                </>
                                            )}
                                        </svg>
                                    ))}
                                </g>
                                <TableBottomBorder deltaY={Object.keys(tables[tableId].fields).length}/>
                                {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                    <TableBottomBorderSelect x={3.5}
                                                             y={42 + 20 * Object.keys(tables[tableId].fields).length} isGroupSelection={selectedTablesId.includes(tableId)}/>
                                )}
                            </svg>
                        ))}
                        {selectedRelationId &&
                            <RelationTypeButton mouseCoords={mouseCoords} selectedRelationId={selectedRelationId}
                                                deleteRelation={deleteRelation} relation={relations[selectedRelationId]}
                                                changeRelationType={changeRelationType}/>
                        }
                        {selectingArea.isSelecting &&
                            <rect x={selectingArea.endMouseCoords.x - selectingArea.startMouseCoords.x > 0 ? selectingArea.startMouseCoords.x : selectingArea.endMouseCoords.x} 
                                  y={selectingArea.endMouseCoords.y - selectingArea.startMouseCoords.y > 0 ? selectingArea.startMouseCoords.y : selectingArea.endMouseCoords.y} 
                                  width={Math.abs(selectingArea.endMouseCoords.x - selectingArea.startMouseCoords.x)} 
                                  height={Math.abs(selectingArea.endMouseCoords.y - selectingArea.startMouseCoords.y)} 
                                  fill={'blue'} 
                                  fillOpacity="0.05"
                                  stroke={'rgba(0,93,231,0.55)'}
                                  strokeWidth={1 / scale}
                                  strokeDasharray={`${10 / scale}, ${10 / scale}`}
                            />
                        }

                    </g>
                </svg>
            </div>
        </div>
    )
}

