import st from "./WorkBench.module.css";
import {useEffect, useState} from "react";
import cn from "classnames";
import Options from "../../resources/Options";
import TableArrow from "../../resources/TableArrow";
import ArrowMenu from "../../resources/ArrowMenu";
import { v4 as uuidv4 } from 'uuid';
import Plus from "../../resources/Plus";
import Trash from "../../resources/Trash";
import {FieldTypes} from "../../service/enum/FielsTypes";
import Key from "../../resources/Key";
import { HexColorPicker } from "react-colorful";
import {useDispatch, useSelector} from "react-redux";
import {updateTablesRedux} from "../../redux/actions/appActions";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Wrench from "../../resources/Wrench";
import NN from "../../resources/NN";
import {Constraints} from "../../service/enum/Constraints";

const WorkBench = ({createTable, selectTable, selectedTableId, selectedTablesId, updateTables, deleteTable, deleteField, updateFieldType, openedTableIds, setOpenedTableIds, switchPrimaryKey, switchConstraint, isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu}) => {

    const dispatch = useDispatch();
    const tables = useSelector((state) => state.tablesRedux);
    const setTables = (tables) => {
        dispatch(updateTablesRedux(tables))
    }
    
    const [tableMenu, setTableMenu] = useState({
        top: 0,
        left: 0,
        tableId: null,
        isChangingColor: false
    });
    const [fieldMenu, setFieldMenu] = useState({
        top: 0,
        left: 0,
        tableId: null,
        fieldId: null
    });
    const [projectId, setProjectId] = useState(1)
    let [colors, setColors] = useState([]) // цвета в памяти браузера
    const [startName, setStartName] = useState("");
    
    const updateTableColor = (e, needSaveToLocalStorage) => {
        if (selectedTableId) {
            if (!needSaveToLocalStorage) {
                tables[selectedTableId].color = e;
            }
            updateTables(tables, "updateTableColor", needSaveToLocalStorage)
        }
    }
    
    const selectSavedTableColor = (color) => {
        tables[selectedTableId].color = color;
        updateTables(tables, "selectSavedTableColor")
    }
    
    const switchWorkBench = () => {
        setTableMenu({
            top: 0,
            left: 0,
            tableId: null,
            isChangingColor: false
        })
        setFieldMenu({
            top: 0,
            left: 0,
            tableId: null,
            fieldId: null
        })
        setIsOpenedWorkBenchMenu(isOpenedWorkBenchMenu => !isOpenedWorkBenchMenu);
    }
    
    const selectObject = (tableId) => {
        selectTable(null, tableId)
    }

    const copyTable = (e, tableId) => {
        e.stopPropagation()
        createTable(390, 50, {...tables[tableId], name: tables[tableId].name + "_copy"});
    }

    const switchTable = (tableId) => {
        if (openedTableIds.has(tableId)) {
            openedTableIds.delete(tableId)
        } else {
            openedTableIds.add(tableId)
        }
        setOpenedTableIds(new Set([...openedTableIds]))
        setFieldMenu({})
    }

    const openTable = (tableId) => {
        if (!openedTableIds.has(tableId)) {
            openedTableIds.add(tableId)
        }
    }
    
    const switchTableMenu = (e, tableId) => {
        if (tableMenu.tableId && tableMenu.tableId === tableId) {
            setTableMenu({})
        } else  {
            setTableMenu({
                top: e.target.getBoundingClientRect().y,
                left: e.target.getBoundingClientRect().right + 15,
                tableId: tableId
            })
            setFieldMenu({})
        }
    }

    const switchFieldMenu = (e, tableId, fieldId) => {
        if (fieldMenu.tableId === tableId && fieldMenu.fieldId === fieldId) {
            setFieldMenu({})
        } else  {
            setFieldMenu({
                top: e.target.getBoundingClientRect().y - 4,
                left: e.target.getBoundingClientRect().right + 15,
                tableId: tableId,
                fieldId: fieldId
            })
            selectObject(tableId)
            setTableMenu({})
        }
    }
    
    const createField = (tableId) => {
        tables[tableId].fields[uuidv4()] = {
            name: "",
            type: "",
            relations: [],
            constraints: []
        }
        updateTables(tables, "createField")
        openTable(tableId)
    }


    const focusName = (value) => {
        setStartName(value)
    }

    const changeTableName = (event, tableId, value) => {
        tables[tableId].name = value
        setTables({...tables})
    }
    const blurTableName = (event, tableId, value) => {
        if (tables[tableId].name !== startName) {
            tables[tableId].name = value
            updateTables(tables, "changeTableName")
            setStartName("");
        }
    }
    
    const changeFieldName = (event, tableId, fieldId, value) => {
        tables[tableId].fields[fieldId].name = value
        setTables({...tables})
    }
    
    const blurFieldName = (event, tableId, fieldId, value) => {
        if (tables[tableId].fields[fieldId].name !== startName) {
            tables[tableId].fields[fieldId].name = value
            updateTables(tables, "blurFieldName")
            setStartName("");
        }
    }

    const createObject = () => {
        // TODO сделать управление размерами панели
        createTable(390,
            50);
    }
    
    const switchColorPalette = () => {
        setTableMenu({...tableMenu, isChangingColor: !tableMenu.isChangingColor})
    }
    
    const saveColor = () => {
        colors.reverse().push(tables[selectedTableId].color)
        localStorage.setItem("savedColors" + projectId, JSON.stringify(colors));
        setColors([...colors].reverse().slice(0, 10))
    }
    
    useEffect(() => {
        const savedColors = localStorage.getItem('savedColors' + projectId);
        if (JSON.parse(savedColors)) {
            setColors([...JSON.parse(savedColors)].reverse())
        }
    }, [])
    
    useEffect(() => {
        if (selectedTableId === null) {
            setTableMenu({})
            setFieldMenu({})
        } else if (selectedTableId !== tableMenu.tableId) {
            setTableMenu({})
        }
        if (selectedTableId !== fieldMenu.tableId) {
            setFieldMenu({})
        }
    },[selectedTableId])

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;
        if (!destination) {
            return;
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return;
        }
        let tableId = draggableId.slice(6);
        if (type === "FIELD") {
            tableId = tableId.slice(37);
        }
        if (type === 'TABLE') {
            // Получаем массив tableIds в текущем порядке
            const tableIds = Object.keys(tables);
            // Перемещаем элемент в новый индекс
            const [movedTableId] = tableIds.splice(source.index, 1);
            tableIds.splice(destination.index, 0, movedTableId);
            // Создаем новый объект tables с обновленным порядком
            const updatedTables = {};
            tableIds.forEach((tableId) => {
                updatedTables[tableId] = tables[tableId];
            });
            // Обновляем состояние таблиц
            setTables(updatedTables);
            updateTables(updatedTables, "onDragTableEnd")
        } else if (type === 'FIELD') {
            // Получаем массив fieldIds в текущем порядке
            const fieldIds = Object.keys(tables[tableId].fields);
            // Перемещаем элемент в новый индекс
            const [movedFieldId] = fieldIds.splice(source.index, 1);
            fieldIds.splice(destination.index, 0, movedFieldId);
            // Создаем новый объект fields с обновленным порядком
            const updatedFields = {};
            fieldIds.forEach((fieldId) => {
                updatedFields[fieldId] = tables[tableId].fields[fieldId];
            });
            const updatedTables = {
                ...tables,
                [tableId]: {
                    ...tables[tableId],
                    fields: updatedFields,
                },
            };
            setTables(updatedTables)
            updateTables(updatedTables, "onDragFieldEnd")
        }
    };
    
    const onDragStart = () => {
        setTableMenu({})
        setFieldMenu({})
    }
    
    return (
        <div className={cn(st.workBenchBlock, {[st.workBenchBlockSwitched]: !isOpenedWorkBenchMenu})}>
            <div className={cn(st.workBenchCloseButton, {[st.workBenchCloseButtonSwitched]: !isOpenedWorkBenchMenu})} onClick={switchWorkBench}><ArrowMenu isOpenedWorkBenchMenu={isOpenedWorkBenchMenu}/></div>
            
            <div className={st.workBenchHeaderBlock}>
                <div className={st.workBenchTitleBlock}>
                    <span className={st.nonSelectable}>WorkBench</span>
                </div>
                <div className={st.workBenchButtons}>
                    <span className={st.nonSelectable}>Tables</span>
                    <div className={st.workBenchCreateButton} onClick={createObject}>
                        <span className={st.nonSelectable}>+ New Table</span>
                    </div>
                </div>
            </div>
            <DragDropContext onDragEnd={(e) => onDragEnd(e)} onBeforeDragStart={onDragStart}>
                <Droppable droppableId="tables" type="TABLE">
                    {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={st.workBenchBodyBlock}
                    >
                        {Object.keys(tables).map((tableId, index) => (
                            <Draggable key={tableId} draggableId={`table-${tableId}`} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={st.tableBlock} key={tableId}
                                    >

                                        <div
                                            className={cn(st.tableHeader, {[st.tableHeaderSelected]: tableId === selectedTableId || selectedTablesId.includes(tableId)})}
                                            onClick={() => selectObject(tableId)}>
                                            <div className={st.tableColorMark}
                                                 style={{backgroundColor: `${tables[tableId].color}`}}></div>
                                            <div className={st.openButtonNNameBlock}>
                                                <div className={st.tableOptionButton}
                                                     onClick={() => switchTable(tableId)}><TableArrow
                                                    selected={tableId === selectedTableId} tableId={tableId}
                                                    openedTableIds={openedTableIds}/></div>
                                                <input id={tableId} className={st.tableNameInput}
                                                       value={tables[tableId].name}
                                                       onFocus={(event) => focusName(event.target.value)}
                                                       onChange={(event) => changeTableName(event, tableId, event.target.value)}
                                                       onBlur={(event) => blurTableName(event, tableId, event.target.value)}/>
                                            </div>
                                            <div className={st.tableOptions}>
                                                <div className={st.tableOptionButton}
                                                     onClick={(e) => createField(tableId)}><Plus
                                                    selected={tableId === selectedTableId}/></div>
                                                <div className={st.tableOptionButton}
                                                     onClick={(e) => switchTableMenu(e, tableId)}><Options
                                                    selected={tableId === selectedTableId}/></div>
                                                {tableId === tableMenu.tableId && selectedTableId &&
                                                    <>
                                                        <div className={st.menuBlock}
                                                             style={{top: tableMenu.top, left: tableMenu.left}}>
                                                            <div className={st.menuItem}
                                                                 onClick={(e) => createField(tableId)}>
                                                                <span>Add field</span>
                                                            </div>
                                                            <div className={st.menuItem}
                                                                 onClick={(e) => copyTable(e, tableId)}>
                                                                <span>Copy table</span>
                                                            </div>
                                                            <div
                                                                className={cn(st.menuItem, {[st.menuItemSelected]: tableMenu.isChangingColor})}
                                                                onClick={(e) => switchColorPalette(e, tableId)}>
                                                                <span>Change color</span>
                                                            </div>
                                                            <div className={st.menuItem}
                                                                 onClick={(e) => deleteTable(e, tableId)}>
                                                                <span>Delete table</span>
                                                            </div>
                                                        </div>
                                                        {tableMenu.isChangingColor &&
                                                            <>
                                                                <div className={st.favColorsBlock} style={{
                                                                    top: tableMenu.top + 105,
                                                                    left: tableMenu.left
                                                                }}>
                                                                    <div className={st.saveColorButton}
                                                                         onClick={saveColor}>
                                                                        <span>Save color</span>
                                                                    </div>
                                                                    <div className={st.colorPalette}>
                                                                        {[...Array(10)].map((colorIndex, id) => (
                                                                            <div key={id} className={st.color}
                                                                                 onClick={() => selectSavedTableColor(colors[id])}
                                                                                 style={{backgroundColor: colors[id] ? colors[id] : ''}}></div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div onMouseUp={() => updateTableColor(null, true)}
                                                                     className={st.paletteBlock} style={{
                                                                    top: (tableMenu.top ? tableMenu.top : 0),
                                                                    left: `${(tableMenu.left ? tableMenu.left : 0) + 155}px`,
                                                                    position: "fixed"
                                                                }}>
                                                                    <HexColorPicker
                                                                        color={tables[selectedTableId]?.color}
                                                                        onChange={(e) => updateTableColor(e, false)}/>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <Droppable droppableId={`fields-${tableId}`} type="FIELD">
                                        {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className={cn(st.tableFieldsBlock, { [st.tableFieldsBlockOpened]: openedTableIds.has(tableId) })}
                                                        style={{ overflowY: Object.keys(tables[tableId].fields).length * 40 > 500 ? "auto" : "hidden" }}
                                                    >
                                                        {Object.keys(tables[tableId].fields).map((fieldId, index) => (
                                                            <Draggable key={fieldId} draggableId={`field-${fieldId}-${tableId}`} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className={st.tableFieldBlock}
                                                                    >
                                                                        <div
                                                                            className={st.tableFieldColorMark}
                                                                            style={{ backgroundColor: `${tables[tableId].color}` }}
                                                                        ></div>
                                                                        <div className={st.inputBlock}>
                                                                            <input
                                                                                id={fieldId}
                                                                                className={st.fieldNameInput}
                                                                                value={tables[tableId].fields[fieldId].name}
                                                                                onFocus={(event) => focusName(event.target.value)}
                                                                                onChange={(event) => changeFieldName(event, tableId, fieldId, event.target.value)}
                                                                                onBlur={(event) => blurFieldName(event, tableId, fieldId, event.target.value)}
                                                                            />
                                                                            <select
                                                                                value={tables[tableId].fields[fieldId].type}
                                                                                className={st.fieldTypeInput}
                                                                                onChange={(event) => updateFieldType(tableId, fieldId, event.target.value)}
                                                                            >
                                                                                {Object.entries(FieldTypes).map(([key, value]) => (
                                                                                    <option key={key} value={value}>{value}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className={st.tableOptions}>
                                                                            <div
                                                                                className={cn(st.tableOptionButton, {[st.primaryKey]: !tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)})}
                                                                                onClick={() => switchPrimaryKey(tableId, fieldId)}
                                                                            >
                                                                                <Key
                                                                                    selected={tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)}/>
                                                                            </div>
                                                                            <div className={st.tableOptionButton} onClick={() => switchConstraint(tableId, fieldId, Constraints.NOT_NULL)}>
                                                                                <NN
                                                                                    selected={tables[tableId].fields[fieldId]?.constraints.includes(Constraints.NOT_NULL)}/>
                                                                            </div>
                                                                            <div className={st.tableOptionButton} onClick={(e) => switchFieldMenu(e, tableId, fieldId)}>
                                                                                <Wrench
                                                                                    selected={tableId === selectedTableId}/>
                                                                            </div>
                                                                            {fieldId === fieldMenu.fieldId &&
                                                                                <div className={st.editMenu} style={{top: fieldMenu.top, left: fieldMenu.left}}>
                                                                                    <div className={st.editMenuArrow}>
                                                                                    </div>
                                                                                        Coming soon....
                                                                                </div>
                                                                            }
                                                                            {/*<div className={st.tableOptionButton}*/}
                                                                            {/*     onClick={(e) => deleteField(e, tableId, fieldId)}>*/}
                                                                            {/*    <Trash*/}
                                                                            {/*        selected={tableId === selectedTableId}/>*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                        )}
                </Droppable>
            </DragDropContext>
            
        </div>
    )
}

export default WorkBench;

