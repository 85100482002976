import React from 'react';
import {TABLE_WIDTH} from "../../service/consts";

const TableBottomBorder = ({color}) => {
    return (
        <svg width={TABLE_WIDTH} height="15.3" x="5" y={1}>
            <rect x="0" y="0" width={TABLE_WIDTH} height="10" rx="6" ry="6" fill={color}/>
        </svg>
);
};

export default TableBottomBorder;