import React from 'react';
import {TABLE_WIDTH} from "../../service/consts";

const TableBottomBorderSelect = ({x, y, isGroupSelection}) => {
    return (
        <svg x={x} y={y} width={TABLE_WIDTH + 3} height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={'M1 2.38419e-07V0.5C1 3.81371 3.68629 6.5 7 6.5H' + (TABLE_WIDTH - 4) + 'C' + (TABLE_WIDTH - 1) + '.314 6.5 ' + (TABLE_WIDTH + 2) + ' 3.81371 ' + (TABLE_WIDTH + 2) + ' 0.5V2.38419e-07'} stroke={isGroupSelection ? '#8741ff' : '#414AFF'}/>
        </svg>
    );
};

export default TableBottomBorderSelect;