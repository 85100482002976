import React from 'react';
import st from "../../pages/Editor/Editor.module.css";
import {RelationTypes} from "../../service/enum/RelationTypes";
import cn from "classnames";

const RelationTypeButton = ({mouseCoords, selectedRelationId, deleteRelation, relation, changeRelationType}) => {
    
    return (
        <svg x={mouseCoords.x - 16} y={mouseCoords.y - 42} style={{transform: "scale(4)"}} width="133" height="43" viewBox="0 0 133 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.71228" width="131.575" height="34.9315" rx="4" fill="#3C5375"/>
            <path d="M4.5 34.8973L3 34L2.97946 32.5L33 32.5L33 34.7062L30.2411 34.7062C28.9728 34.7891 27.4324 35.0039 25.9894 35.5078C20.1466 37.5484 16.9492 42.3972 16.9492 42.3972C16.9492 42.3972 13.7519 37.5484 7.90912 35.5078C6.46618 35.0039 5.81653 34.8878 4.5 34.8973Z" fill="#3C5375"/>
            <g className={st.relationPowerGroup} onClick={() => changeRelationType(relation, RelationTypes.ONE_TO_ONE)}>
                <rect className={cn(st.relationPowerButton, {[st.relationPowerButtonSelected]: relation.relationType === RelationTypes.ONE_TO_ONE})} x="6.53418" y="4.65753" width="25.6164" height="25.6164" rx="3" fill="#AAC1FD"/>
                <path d="M13.59 21.4794H12.5964V14.9267L10.55 15.7055V14.7978L13.5094 13.6591H13.59V21.4794ZM18.9718 20.8349C18.9718 20.6236 19.0363 20.4428 19.1652 20.2924C19.2941 20.142 19.4893 20.0668 19.7506 20.0668C20.0085 20.0668 20.2036 20.142 20.3361 20.2924C20.4722 20.4392 20.5402 20.6201 20.5402 20.8349C20.5402 21.0462 20.4722 21.2234 20.3361 21.3666C20.2 21.5099 20.0049 21.5815 19.7506 21.5815C19.4928 21.5815 19.2977 21.5099 19.1652 21.3666C19.0363 21.2198 18.9718 21.0426 18.9718 20.8349ZM18.9718 16.1298C18.9718 15.9186 19.0363 15.7377 19.1652 15.5873C19.2941 15.437 19.4893 15.3618 19.7506 15.3618C20.0085 15.3618 20.2036 15.437 20.3361 15.5873C20.4722 15.7342 20.5402 15.915 20.5402 16.1298C20.5402 16.3411 20.4722 16.5183 20.3361 16.6616C20.2 16.8048 20.0049 16.8764 19.7506 16.8764C19.4928 16.8764 19.2977 16.8048 19.1652 16.6616C19.0363 16.5148 18.9718 16.3375 18.9718 16.1298ZM26.8029 21.4794H25.8092V14.9267L23.7629 15.7055V14.7978L26.7223 13.6591H26.8029V21.4794Z" fill="#3C5375"/>
            </g>
            <g className={st.relationPowerGroup} onClick={() => changeRelationType(relation, RelationTypes.ONE_TO_MANY)}>
                <rect className={cn(st.relationPowerButton, {[st.relationPowerButtonSelected]: relation.relationType === RelationTypes.ONE_TO_MANY})} x="37.9726" y="4.65753" width="25.6164" height="25.6164" rx="3" fill="#AAC1FD"/>
                <path d="M45.0284 21.4794H44.0347V14.9267L41.9883 15.7055V14.7978L44.9478 13.6591H45.0284V21.4794ZM50.4102 20.8349C50.4102 20.6236 50.4746 20.4428 50.6036 20.2924C50.7325 20.142 50.9276 20.0668 51.189 20.0668C51.4468 20.0668 51.642 20.142 51.7745 20.2924C51.9105 20.4392 51.9786 20.6201 51.9786 20.8349C51.9786 21.0462 51.9105 21.2234 51.7745 21.3666C51.6384 21.5099 51.4432 21.5815 51.189 21.5815C50.9312 21.5815 50.736 21.5099 50.6036 21.3666C50.4746 21.2198 50.4102 21.0426 50.4102 20.8349ZM50.4102 16.1298C50.4102 15.9186 50.4746 15.7377 50.6036 15.5873C50.7325 15.437 50.9276 15.3618 51.189 15.3618C51.4468 15.3618 51.642 15.437 51.7745 15.5873C51.9105 15.7342 51.9786 15.915 51.9786 16.1298C51.9786 16.3411 51.9105 16.5183 51.7745 16.6616C51.6384 16.8048 51.4432 16.8764 51.189 16.8764C50.9312 16.8764 50.736 16.8048 50.6036 16.6616C50.4746 16.5148 50.4102 16.3375 50.4102 16.1298ZM59.917 21.4794H58.9073L55.8726 15.6357L55.8565 21.4794H54.8521V13.6591H55.8619L58.8965 19.4921L58.9126 13.6591H59.917V21.4794Z" fill="#3C5375"/>
            </g>
            <g className={st.relationPowerGroup}  onClick={() => changeRelationType(relation, RelationTypes.MANY_TO_ONE)}>
                <rect className={cn(st.relationPowerButton, {[st.relationPowerButtonSelected]: relation.relationType === RelationTypes.MANY_TO_ONE})} x="69.4109" y="4.65753" width="25.6164" height="25.6164" rx="3" fill="#AAC1FD"/>
                <path d="M78.1425 21.4794H77.1327L74.0981 15.6357L74.0819 21.4794H73.0776V13.6591H74.0873L77.122 19.4921L77.1381 13.6591H78.1425V21.4794ZM81.8485 20.8349C81.8485 20.6236 81.913 20.4428 82.0419 20.2924C82.1708 20.142 82.366 20.0668 82.6274 20.0668C82.8852 20.0668 83.0803 20.142 83.2128 20.2924C83.3489 20.4392 83.4169 20.6201 83.4169 20.8349C83.4169 21.0462 83.3489 21.2234 83.2128 21.3666C83.0767 21.5099 82.8816 21.5815 82.6274 21.5815C82.3695 21.5815 82.1744 21.5099 82.0419 21.3666C81.913 21.2198 81.8485 21.0426 81.8485 20.8349ZM81.8485 16.1298C81.8485 15.9186 81.913 15.7377 82.0419 15.5873C82.1708 15.437 82.366 15.3618 82.6274 15.3618C82.8852 15.3618 83.0803 15.437 83.2128 15.5873C83.3489 15.7342 83.4169 15.915 83.4169 16.1298C83.4169 16.3411 83.3489 16.5183 83.2128 16.6616C83.0767 16.8048 82.8816 16.8764 82.6274 16.8764C82.3695 16.8764 82.1744 16.8048 82.0419 16.6616C81.913 16.5148 81.8485 16.3375 81.8485 16.1298ZM89.6796 21.4794H88.686V14.9267L86.6396 15.7055V14.7978L89.599 13.6591H89.6796V21.4794Z" fill="#3C5375"/>
            </g>
            <path d="M101.431 4.65753V30.274" stroke="#AAC1FD" strokeWidth="1.5" strokeLinecap="round"/>
            <g className={st.relationDeleteButton} onClick={() => deleteRelation(selectedRelationId, true)}>
                <path d="M107.518 11.1146H109.4L124.455 11.1146" stroke="#AAC1FD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M123.396 10.9029V24.9814C123.396 25.5148 123.173 26.0263 122.776 26.4035C122.379 26.7807 121.84 26.9926 121.279 26.9926H110.694C110.132 26.9926 109.594 26.7807 109.197 26.4035C108.8 26.0263 108.577 25.5148 108.577 24.9814V10.9029M111.752 10.9029V8.8917C111.752 8.3583 111.975 7.84674 112.372 7.46956C112.769 7.09239 113.308 6.88049 113.869 6.88049H118.103C118.665 6.88049 119.203 7.09239 119.6 7.46956C119.997 7.84674 120.22 8.3583 120.22 8.8917V10.9029" stroke="#AAC1FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M113.516 16.2308V23.6405" stroke="#AAC1FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M118.456 16.2308V23.6405" stroke="#AAC1FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="103.178" y="4.65753" width="25.6164" height="25.6164" fill="#D9D9D9" fillOpacity="0.01" pointerEvents="all" strokeOpacity={0}/>
            </g>

        </svg>

    );
};

export default RelationTypeButton;