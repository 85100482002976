import React from 'react';

const NN = ({selected}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.875 18H9.40625L4.99219 9.5L4.96875 18H3.50781V6.625H4.97656L9.39062 15.1094L9.41406 6.625H10.875V18ZM20.4844 18H19.0156L14.6016 9.5L14.5781 18H13.1172V6.625H14.5859L19 15.1094L19.0234 6.625H20.4844V18Z"
                fill={selected ? "#295bd5" : "#A3A2A2"}/>

        </svg>

    );
};

export default NN;