import React, {useEffect, useRef, useState} from 'react';
import st from "../../pages/Editor/Editor.module.css";
import st2 from "./TableParts.module.css";
import MiniKey from "../../resources/MiniKey";
import {TABLE_WIDTH} from "../../service/consts";
import {Constraints} from "../../service/enum/Constraints";

const TableField = ({deltaY, field, tableId, fieldId, selectRelationField, isPrimaryKey, isForeignKey, setOpenedTableIds}) => {
    const fieldNameTextRef = useRef();
    const fieldTypeTextRef = useRef();
    const padding = 10;
    const MAX_LENGTH = 20;

    const [value, setValue] = useState("");

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            text = text.substring(0, maxLength) + '…';
        }
        setValue(text)
    }
    
    const processDoubleClick = () => {
        setOpenedTableIds(openedTableIds => (new Set([...openedTableIds, tableId])))
        const inputElement = document.getElementById(fieldId);
        inputElement.focus();
    }

    useEffect(() => {
        truncateText(field.name, MAX_LENGTH)
    }, [field.name])
    
    return (
        <svg onDoubleClick={processDoubleClick} width={TABLE_WIDTH} height="21" x="5" y={43 + 20 * deltaY}>
            <g className={st2.fieldGroup} onMouseUp={(e) => selectRelationField(e, tableId, fieldId)}>
                <rect x="0" y="1" width={TABLE_WIDTH} height="20" className={st2.field}/>
                <g style={{transform: `translate(${padding}px, 22%)`, opacity: isPrimaryKey ? 1 : 0}}>
                    <MiniKey color={'#3DA71D'}/>
                </g>
                {isForeignKey &&
                    <g style={{transform: `translate(${isPrimaryKey ? padding + 15 : padding}px, 22%)`}}>
                        <MiniKey color={'#3283cd'}/>
                    </g>
                }
                <text ref={fieldNameTextRef} x="0" y="0" dominantBaseline="middle" textAnchor="start" className={st.nonSelectable} style={{transform: `translate(${padding + (isPrimaryKey && isForeignKey ? 30 : (isPrimaryKey ? 15 : isForeignKey ? 15 : 0))}px, 55%)`, fontSize: 11, fill: "#828181"}}>{value}</text>
                <text ref={fieldTypeTextRef}
                          x="0" y="0" dominantBaseline="middle" textAnchor="end" className={st.nonSelectable} style={{transform: `translate(calc(100% - ${padding}px), 55%)`, fontSize: 11, fill: "#828181"}}>{field.type}{field?.constraints.includes(Constraints.NOT_NULL) ? '!' : ''}</text>
            </g>
        </svg>
    );
};

export default TableField;